import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import GlobalBanner from '../components/globalBanner'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { hasCookie } from '../services/cookies';

class Blog extends Component {
  render() {

    const blogs = this.props.data.allWpPost;
    const data = this.props.data.wpPage;

    if (hasCookie() == true) {
      return (
        <Layout>
          <div className="blog-template">

          <GlobalBanner pageTitle={data.titleSubtitleACF.titlePage !== undefined && data.titleSubtitleACF.titlePage}
                        pageSubTitle={data.titleSubtitleACF.subtitlePage !== undefined && data.titleSubtitleACF.subtitlePage}
                        bgSharpImg={data.featuredImage.node.localFile !== null && data.featuredImage.node.localFile.childImageSharp !== null && data.featuredImage.node.localFile}
                        bgImg={data.featuredImage.node.sourceUrl}
          />

            <div className="ctn-main uk-padding">
              <div className="blog-content uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m push-up-small" data-uk-grid data-uk-height-match="target: h3">
                {blogs.edges.map(({ node }) => (
                  <div key={node.id} className="uk-margin-bottom uk-padding uk-padding-remove-bottom">
                    <div className="uk-card-media-top">
                      <Link className="uk-cover-container" to={`/blog/${node.slug}/`}>
                        {node.featuredImage.node.localFile != undefined &&
                          <GatsbyImage image={getImage(node.featuredImage.node.localFile)} />
                        }
                      </Link>
                    </div>
                    <div className="uk-margin-top">
                      <Link to={`/blog/${node.slug}/`}>
                        <h3 className="colored uk-text-uppercase" dangerouslySetInnerHTML={{ __html: node.title }} />
                      </Link>
                      <div className="summary">
                        <Link to={`/blog/${node.slug}/`}>Read more</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>


            </div> {/* End .ctn-main.uk-padding */}
          </div> {/* End .blog-template */}
        </Layout>
      );
    } else {
      return (
        <></>
      )
    }
  }
}

export default Blog;

export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "blog"}) {
      id
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                sizes: "(min-width: 1429px) 1920px, 100vw"
              )
            }
          }
          sourceUrl
        }
      }
      titleSubtitleACF {
        subtitlePage
        titlePage
      }
    }
    allWpPost (
      sort: {
        fields: [date]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          slug
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                    sizes: "(min-width: 1429px) 1920px, 100vw"
                  )
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
  }
`
